<template>
     <div class="body">
          <div class="container">
               <div class="section-title">Contact Us</div>

               <div class="section-header">We are happy to meet your business needs.</div>

               <div class="columns details">
                    <div class="column is-half">
                         <div class="">
                              <p>
                                   <span class="green">MUMBAI </span><br />
                                   B-621 Jaswanti Business Centre,<br />
                                   Ramchandra Ln, Kanchpada,<br />
                                   Malad West, Mumbai,<br />
                                   Maharashtra - 400064
                                   <!-- <a href="tel:+919821934804 ">+91 9821934804</a>
                                   <br />
                                   <a href="mailto:urmish@ctl.in">urmish@ctl.in </a><br /> -->
                              </p>
                              <p>
                                   <span class="green">BENGALURU</span>

                                   <br />
                                   3, Maruthi Corner, <br />
                                   Banaswadi Main Road, <br />
                                   Bengaluru, <br />
                                   Karnataka - 560043<br />

                                   <!-- <a href="tel:+919847427427 ">+91 9847427427 </a>  -->
                                   <!-- <a href="mailto:habeeb@ctl.in">habeeb@ctl.in </a> -->
                              </p>

                              <p>
                                   <span class="green">KOCHI </span><br />

                                   5C, K G Oxford Centre,<br />
                                   Sreekandath Road,<br />
                                   Ravipuram, Ernakulam,<br />
                                   Kerala - 682016

                                   <!-- <a href="tel:+919847427427 ">+91 9847427427</a> | <a href="tel:+919895016067 ">+91 9895016067</a> -->
                                   <br />
                                   <!-- <a href="mailto:habeeb@ctl.in">habeeb@ctl.in </a><br /> -->
                              </p>
                         </div>
                    </div>
                    <div class="column is-half">
                         <div class="mb-20">
                              <span class="green" style="font-weight:300">CONNECT WITH US</span>
                         </div>
                         <div class="inputcontainer">
                              <input type="text" class="input" placeholder="Name" v-model="name" />
                         </div>
                         <div class="inputcontainer">
                              <input type="text" class="input" placeholder="Email" v-model="email" />
                         </div>
                         <div class="inputcontainer">
                              <input type="text" class="input" placeholder="Phone" v-model="phone" />
                         </div>

                         <div class="inputcontainer">
                              <textarea ref="textarea" @input="autoResize" placeholder="Message" class="input" name="" id="" rows="1"></textarea>
                         </div>
                         <button class=" button outlined" style="text-align:left">
                              Send
                              <span class="arrowsvg"
                                   ><svg xmlns="http://www.w3.org/2000/svg" width="42.74" height="19.504" viewBox="0 0 42.74 19.504">
                                        <g id="Group_57" data-name="Group 57" transform="translate(0 0.354)">
                                             <line
                                                  id="Line_40"
                                                  data-name="Line 40"
                                                  x2="42"
                                                  transform="translate(0 8.86)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                             <path
                                                  id="Path_21"
                                                  data-name="Path 21"
                                                  d="M1103.635,126.653l9.4,9.4-9.4,9.4"
                                                  transform="translate(-1071 -126.653)"
                                                  fill="none"
                                                  stroke="#89c184"
                                                  stroke-width="1"
                                             />
                                        </g>
                                   </svg>
                              </span>
                         </button>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {
          data() {
               return {
                    textarea: this.$refs.textarea,
                    email: '',
                    name: '',
                    phone: '',
               };
          },
          mounted() {},
          methods: {
               autoResize(x) {
                    x.srcElement.style.height = 'auto';
                    x.srcElement.style.height = x.srcElement.scrollHeight + 'px';
               },
          },
     };
</script>

<style lang="scss" scoped>
     .body {
          padding-top: 60px;
          padding-bottom: 120px;
     }
     .section-header {
          max-width: 650px;
     }
     button.outlined {
          margin-top: 40px;
          height: 53px;
          padding: 0 120px 0 20px;

          position: relative;
          text-align: left !important;

          .arrowsvg {
               position: absolute;
               top: 17px;
               right: 20px;
          }
     }

     .sideimg {
          width: 100%;
     }
     .column {
          padding-right: 80px;
     }
     .section-title-3 {
          margin-bottom: 20px;
     }
     p {
          padding-bottom: 40px;
     }
     p.large {
          padding-top: 30px;
          font-weight: 300;
          font-size: 24px;
     }
</style>
